import {TextLogo} from "../../../components/TextLogo";
import {Input} from "../../../components/Input";
import {Button} from "../../../components/Button";
import {Link} from "../../../components/Link";
import {WizardStepProps} from "../../../types/wizard";
import {useNavigate} from "react-router-dom"
import {Flex, Spinner, Stack, useToast} from "@chakra-ui/react";
import {Control, useForm} from "react-hook-form";
import {LoginProps, UserProps} from "../../../types";
import {userLogin} from "../../../services/login"
import {useUser} from "../../../hooks/useUser"
import {Logo} from "../../../components/Logo";
import {useState} from "react";
import { IconButton } from "@chakra-ui/react";
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';



export function SignIn({...props}: Partial<WizardStepProps>) {
    const toast = useToast()
    const [showPassword, setShowPassword] = useState(false);
    const {storeUser} = useUser()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const {
        control,
        watch,
        getValues,
        reset,
        resetField,
        trigger
    } = useForm<Partial<LoginProps>>()

    const handleShowPassword = () => {
        setShowPassword(!showPassword);
      };
      
    const handleSignIn = async () => {
        if (!await trigger()) {
            return
        }
        const payload = getValues()


        setIsLoading(true)
        const userResponse = await userLogin({...payload, registration: payload?.email} as LoginProps)

        setIsLoading(false)
        if (userResponse.status !== 200) {

            return toast({
                position: 'top-right',
                status: "error",
                title: "Não autorizado",
                description: "E-mail ou/e senha incorretos",
                isClosable: true
            })
        }
        const userData = {
            ...userResponse.data.user,
            token: userResponse.data.token,
            type: userResponse.data.user?.profile_id ? "administrator" : "affiliate",
            admin: userResponse.data.user?.profile_id === 1
        }

        storeUser(userData)

        toast({
            position: 'top-right',
            status: "success",
            title: "Autorizado",
            description: "Login efetuado com sucesso",
            isClosable: true
        })
        return navigate('/inicio')
    }
    return (
        <Flex className={"w-full flex-col items-center justify-center gap-5"}>
            <Logo/>
            <Stack className={`flex-1 w-full`}>
                <label >Login</label>
                <Input rules={{required: true}} control={control as Control<any>} name={"email"}
                       placeholder={""} className={"w-full"}
                       labelClassName={"w-full"}/>
                <label  >Senha</label>
                <div className="flex relative">
                <Input style={
                    {
                        borderTopRightRadius:0,
                        borderBottomRightRadius:0,
                    }
                } className="w-full" rules={{required: true}} control={control as Control<any>} name={"password"} type={showPassword ? "text" : "password"}
                       placeholder={""}
                       labelClassName={"w-full"}/>
                <IconButton className="absolute" style={
                    {
                        borderTopLeftRadius:0,
                        borderBottomLeftRadius:0,
                    }
                } aria-label="Mostrar senha" 
                icon={showPassword ? <AiOutlineEye/> : <AiOutlineEyeInvisible/> }
                onClick={handleShowPassword}></IconButton>
                </div>
               
            </Stack>
            <Button bgColor={"#02579A"}  isDisabled={isLoading} onClick={handleSignIn} className={`!text-whiter  w-full`}>{isLoading &&
                <Spinner size={"xs"} className={`!text-primary mr-2 `}/>} Entrar</Button>

            <Link onClick={() => props?.goToStep && props.goToStep(2)}>Esqueci minha senha</Link>
        </Flex>
    )
}